export class NotificationMedia {
  /**
   * media's id
   */
  id: Number;
  /**
   * media's name
   */
  name: string;
  /**
   * media's type
   */
  type: string;
  /**
   * media's url
   */
  url: string;
  /**
   * urlMediaDelivery
   */
  urlMediaDelivery: string;
  /**
   * width
   */
  width: number;
  /**
   * height
   */
  height: number;
  /**
   *
   */
  randomNumber: number;
  /**
   * mediaNameEncode
   */
  mediaNameEncode: string;
  /**
   * size
   */
  size: number;
  constructor(name?: string, type?: string) {
    this.name = name;
    this.type = type;
  }
}
