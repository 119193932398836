import { Component, OnInit } from '@angular/core';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { MenuActionService } from 'app/service/menu-action.service';

@Component({
  selector: 'menu-schedule-operation-manager',
  templateUrl: './menu-schedule-operation-manager.component.html',
  styleUrls: ['./menu-schedule-operation-manager.component.scss']
})
export class MenuScheduleOperationManagerComponent implements OnInit {
  /**
   * MODULE_NAME
   */
  public MODULE_NAME = MODULE_NAME;
  /**
   * FIELD_COMPONENT
   */
  public FIELD_COMPONENT = FIELD_COMPONENT;
  /**
   * isCheckWidthMenu
   */
  public isCheckWidthMenu: boolean;
  /**
   * isCheckMessage
   */
  public isCheckMessage: boolean;
  /**
   * isCheckEmergency
   */
  public isCheckEmergency: boolean;
  /**
   * isCheckUpdateData
   */
  public isCheckUpdateData: boolean;
  /**
   * isCheckNetWork
   */
  public isCheckNetWork: boolean;
  /**
   * true if network ok
   */
  public isNetWorkOk: boolean;
  public isDynamicMessageSelected: boolean;
  public isEditedRow: boolean;
  public enableEdit: boolean;
  public enableUndelete: boolean;
  public disableDelete: boolean;
  isRoot: boolean;
  public isEditDynamicMessageMode: boolean;
  public isEditEmergencyMode: boolean;
  public isDisabled: boolean;
  public hasArea: boolean;
  public isTabOperationSchedule: boolean;
  public isTabNotificationRegistration: boolean;
  public isEditPlaylist: boolean;
  public isInPreviewArea: boolean;

  constructor(private menuActionService: MenuActionService, private dataService: DataService) {
    this.dataService.currentData.subscribe(data => {
      switch (data[0]) {
        case Constant.IS_NETWORK_OK: {
          this.isNetWorkOk = <boolean>data[1];
          break;
        }
        case Constant.IS_EDIT_ROW: {
          this.isEditedRow = <boolean>data[1];
          break;
        }
        case 'enableEdit': {
          this.enableEdit = <boolean>data[1];
          if (data[1] && data[1] == 'undelete') {
            this.enableUndelete = true;
          } else {
            this.enableUndelete = false;
          }
          if (data[1] && data[1] == 'disableDelete') {
            this.disableDelete = true;
          } else {
            this.disableDelete = false;
          }
          break;
        }
        case `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:isEditDynamicMessageMode`: {
          this.isEditDynamicMessageMode = <boolean>data[1];
          break;
        }
        case `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:isEditEmergencyMode`: {
          this.isEditEmergencyMode = <boolean>data[1];
          break;
        }
        case `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:isSelectDynamicMessage`: {
          this.isDynamicMessageSelected = <boolean>data[1];
          break;
        }
        case Constant.HAS_AREA: {
          this.hasArea = <boolean>data[1];
          break;
        }
        case Constant.IS_ROOT: {
          this.isRoot = <boolean>data[1];
          break;
        }
        case Constant.IS_TAB_OPERATION_SCHEDULE: {
          this.isTabOperationSchedule = <boolean>data[1];
          break;
        }
        case Constant.IS_TAB_NOTIFICATION_REGISTRATION: {
          this.isTabNotificationRegistration = <boolean>data[1];
          break;
        }
        case Constant.IS_EDIT_PLAYLIST: {
          this.isEditPlaylist = <boolean>data[1];
          break;
        }
        case Constant.IS_IN_PREVIEW_AREA: {
          this.isInPreviewArea = <boolean>data[1];
          break;
        }
      }
      this.isDisabled = this.isEditDynamicMessageMode || this.isEditEmergencyMode;
    });
  }

  ngOnInit(): void {
    this.handlingDisplayMenu();
  }

  /**
   * open dynamic message
   * @param moduleName
   */
  public openDynamicMessage(moduleName: string) {
    this.menuActionService.openDynamicMessage(moduleName);
  }

  /**
   * open emergency
   *
   * @param moduleName
   */
  public openEmergency(moduleName: string) {
    this.menuActionService.emergency(moduleName);
  }

  public updateData(moduleName: string) {
    if (this.isEditedRow) {
      return;
    }
    this.menuActionService.updateData(moduleName);
  }

  /**
   * execute action for insert row in menu
   *
   * @param {string} moduleName current module name
   */
  public insertRow(moduleName: string) {
    this.menuActionService.insertRow(moduleName);
  }

  /**
   * execute action for duplicate row in menu
   *
   * @param {string} moduleName current module name
   */
  public duplicateRow(moduleName: string) {
    this.menuActionService.duplicateRow(moduleName);
  }

  /**
   * execute action for edit row in menu
   *
   * @param {string} moduleName current module name
   */
  public editRow(moduleName: string) {
    this.menuActionService.editRow(moduleName);
  }

  /**
   * execute action for duplicate row in menu
   *
   * @param {string} moduleName current module name
   */
  public deleteRow(moduleName: string) {
    this.menuActionService.deleteRow(moduleName);
  }

  /**
   * execute action for show/hidden item in menu
   *
   * @param {string} moduleName current module name
   */
  public showHiddenItemSetting(moduleName: string) {
    this.menuActionService.showHiddenItemSetting(moduleName);
  }

  /**
   * execute action for show/hidden item in menu
   *
   * @param {string} moduleName current module name
   */
  public showAreaSetting(moduleName: string) {
    this.menuActionService.showAreaSetting(moduleName);
  }

  /**
   * execute action for show/hidden item in menu
   *
   * @param {string} moduleName current module name
   */
  public showAreaItemSetting(moduleName: string) {
    this.menuActionService.showAreaItemSetting(moduleName);
  }

  /**
   * execute action for clear field free area in menu
   * @param {string} moduleName current module name
   */
  clearField(moduleName: string) {
    this.menuActionService.clearField(moduleName);
  }

  /**
   * importFile
   * @param moduleName
   */
  importFile(moduleName: string) {
    this.menuActionService.importMedia(moduleName);
  }

  /**
   * addNewSequence
   * @param moduleName
   */
  addNewSequence(moduleName: string) {
    this.menuActionService.add(moduleName);
  }

  /**
   * editNewSequence
   * @param moduleName
   */
  editNewSequence(moduleName: string) {
    this.menuActionService.edit(moduleName);
  }

  /**
   * duplicateNewSequence
   * @param moduleName
   */
  duplicateNewSequence(moduleName: string) {
    this.menuActionService.duplicate(moduleName);
  }

  /**
   * deleteNewSequence
   * @param moduleName
   */
  deleteNewSequence(moduleName: string) {
    this.menuActionService.delete(moduleName);
  }

  /**
   * editText
   * @param moduleName
   */
  editText(moduleName: string) {
    this.menuActionService.editDetail(moduleName);
  }

  /**
   * deleteSequence
   * @param moduleName
   */
  deleteSequence(moduleName: string) {
    this.menuActionService.deleteItem(moduleName);
  }

  /**
   * timelineSetting
   * @param moduleName
   */
  timelineSetting(moduleName: string) {
    this.menuActionService.setting(moduleName);
  }

  /**
   * handle  menu  when resize screen
   */
  private handlingDisplayMenu(): void {
    this.isCheckWidthMenu = true;
    this.isCheckMessage = true;
    this.isCheckEmergency = true;
    this.isCheckUpdateData = true;
    this.isCheckNetWork = true;
    this.dataService.currentData.subscribe(data => {
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:checkMessage`) {
        this.isCheckMessage = <boolean>data[1];
      }
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:checkEmergency`) {
        this.isCheckEmergency = <boolean>data[1];
      }
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:checkUpdateData`) {
        this.isCheckUpdateData = <boolean>data[1];
      }
      if (data[0] == `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:checkNetWork`) {
        this.isCheckNetWork = <boolean>data[1];
      }
      this.isCheckWidthMenu = this.isCheckEmergency && this.isCheckUpdateData && this.isCheckNetWork;
    });
  }
}
